<template>
  <div class="wrap" v-if="items">
    <div class="product">
      <div class="product-img">
        <img :src="items.cover" alt="" style="object-fit: cover" />
      </div>
      <div class="product-desc">
        <div style="font-weight: bold; font-size: 16px">{{ items.title }}</div>
        <div style="display:flex;align-items:center">
          <div style="font-size: 14px; color: #878e99; margin-top: 5px">
            <span>官方价：</span>
            <span style="margin-left: 5px">¥{{ items.price }}</span>
          </div>
          <div style="height:12px;width:1px;background:#DDDDDD;margin-left:10px;margin-top:5px;margin-right:10px;"></div>
          <div style="font-size: 14px; color: #878e99; margin-top: 5px">
            <span>挂售参考价</span>
            <span style="color: red; margin-left: 5px">{{ saleminIng }}</span>
          </div>
        </div>
        <div class="product-price" style="margin-top:12px">
          <div class="flex-colume">
            <div class="des">在售最低</div>
            <div class="price">
              {{ sale_min_price }}
            </div>
          </div>
          <div style="color: #dddddd">｜</div>
          <div class="flex-colume">
            <div class="des">求购最高</div>
            <div class="price">
              {{ entrust_max_price }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="myprice">
      <div style="">我的定价 ¥</div>
      <Field readonly ref="inp"  plain v-model="price"  type="number" placeholder="请输入挂售定价" @click="focusa" @touchstart.native.stop="show = true"/>
      <div class="flex" v-show="price" style="position:absolute;right:30px;" @click="focusa">
        <img
          src="https://cdn.vgn.cn/static/market/change.png"
          alt=""
        />
        <div style="margin-left: 2px">修改</div>
      </div>
    </div>
    <div style="background: white">
      <div class="intro" style="margin-top: 10px">
        <Tabs v-model="active" line-width="20px" color="black" sticky>
          <Tab title="基本介绍">
            <div style="background: white" v-html="items.detail"></div>
          </Tab>
          <Tab title="当前求购">
            <CurrentInfo
              v-if="playerList.length > 0"
              :playerList="playerList"
            />
            <div v-else style="height: 600px; position: relative">
              <EmptyBox style="z-index: 2" tip="暂无玩家求购" />
            </div>
          </Tab>
        </Tabs>
        <div class="opc"></div>
      </div>
    </div>
    <BottomLayout :price="price" class="button" :detail_id="items.detail_id" />
    <NumberKeyboard
      :show="show"
      v-model="price" 
      @blur="show = false"
      @input="onInput"
      @delete="onDelete"
      extra-key="."
      close-button-text="完成"
    />
  </div>
</template>

<script>
import { Component, Vue, Watch, Ref } from "vue-property-decorator";
import { Tab, Tabs,Field } from "vant";
import { NumberKeyboard } from 'vant';
import AttentionItem from "@/components/AttentionItem.vue";
import BottomLayout from "@/views/hang-sell/components/BottomLayout.vue";
import CurrentInfo from "@/views/market/components/CurrentInfo.vue";
import EmptyBox from "@/components/EmptyBox.vue";
import SearchApi from "@/api/bazaar.api";
@Component({
  components: {
    AttentionItem,
    Field,
    BottomLayout,
    NumberKeyboard,
    CurrentInfo,
    EmptyBox,
    Tabs,
    Tab,
  },
})
export default class HangSell extends Vue {
  active = 0;
  list = {};
  show=false
  playerList = [];
  price = "";
  items = [];
  saleminIng = "";
  sale_min_price = "";
  entrust_max_price = "";
  @Ref("inp") inpRef
  @Watch("price")
  watchPrice(e) {
     let val = String(e)
    let res = val.match(/^[0-9]{0,6}(\.[0-9]{0,2})?$/g)
    if(res){
          val = val.replace(/[^\d.]/g,"") //清除非 数字和小数点的字符
          val = val.replace(/\.{2,}/g,".") //清除第二个小数点
          val = val.replace(/^\./g,""); //验证第一个字符是数字而不是字符
          val = val.replace(".","$#$").replace(/\./g,"").replace("$#$",".");
          val = val.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3'); //保留两位小数
          val = val.indexOf(".") > 0? val.split(".")[0].substring(0, 6) + "." + val.split(".")[1]: val.substring(0, 6); //限制只能输入7位正整数
          this.price = val;
    }else{
      this.price = this.price.substring(0,this.price.length-1)
    }
  }
  focusa() {
    this.inpRef.focus()
    this.show = true
  }
  onInput(value){
    this.price = String(this.price)+ String(value)
  }
  onDelete(){
    this.price = String(this.price).slice(0,String(this.price.length-1))
  }
  async created() {
    this.items = await SearchApi.getProductDetail(this.$route.query.detail_id);
    this.playerList = await SearchApi.getPlayerList(
      this.$route.query.detail_id
    );
    this.entrust_max_price =
      this.items.entrust_max_price == "0.00"
        ? "-"
        : `¥${this.items.entrust_max_price}`;
        if(this.items.sell_reference_price){
          this.saleminIng = `¥${(Number(this.items.sell_reference_price)).toFixed(2)}`
        }else{
          this.saleminIng = "-"
        }
    this.sale_min_price =
      this.items.sale_min_price == "0.00"
        ? "-"
        : `¥${this.items.sale_min_price}`;
  }
}
</script>

<style lang="scss" scoped>
.van-cell::after{
  border:none;
}
.myprice {
  display: flex;
  background: white;
  padding: 16px 20px;
  padding-bottom: 16px;
  font-size: 14px;
  height: 30px;
  align-items: center;
  .van-cell {
     width: 250px!important;
      font-size: 14px;
      font-weight: 800;
  }
  .flex {
    display: flex;
    align-items: center;
    color: #878e99;
    font-size: 12px;
    img {
      width: 10px;
      height: 10px;
    }
  }
}
.product {
  border-bottom: 1px solid #f2f2f2;

  .product-img {
    width: 100%;
    height: 211px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .product-desc {
    background: white;
    height: 155px;
    padding: 20px 20px 0px 20px;
  }
  .product-price {
    height: 73px;
    background: #f8f8f8;
    border-radius: 4px;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 62px;
    box-sizing: border-box;
    .flex-colume {
      display: flex;
      align-items: center;
      flex-direction: column;
      .des {
        font-size: 12px;
        color: #878e99;
      }
      .price {
        color: #f83737;
        font-size: 16px;
        font-weight: bold;
        margin-top: 4px;
      }
    }
  }
}
.opc {
  height: 100px;
  width: 100%;
}
.wrap {
  position: relative;
  .button {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0 20px;
    box-sizing: border-box;
    height: 80px;
  }
}

.intro {
  ::v-deep {
    .van-sticky--fixed {
      background: white;
    }
    .van-tabs__wrap {
      width: 200px;
      .van-tabs__nav {
        .van-tab--active {
          font-weight: 800;
        }
        .van-tabs__line {
          bottom: 19px !important;
        }
      }
    }
  }
}
.desc {
  height: 128px;
  background: #f8f8f8;
  font-size: 13px;
  padding: 0 20px;
  font-weight: 400;
  color: #969696;
}

.bottom-flex {
  display: flex;
  z-index: 100;
  justify-content: space-between;
  align-items: center;
  background: white;
  .request-buy {
    width: 49%;
    height: 43px;
    background: linear-gradient(90deg, #ffa701 0%, #feab00 100%);
    border-radius: 24px;
    display: flex;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    color: white;
  }
  .buy {
    width: 49%;
    height: 43px;
    background: linear-gradient(270deg, #ff2626 0%, #ff6e4e 100%);
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    flex-direction: column;
    .buy-now {
      font-size: 15px;
      font-weight: bold;
    }
    .low-price {
      font-size: 12px;
      font-weight: 400;
    }
  }
}
</style>
