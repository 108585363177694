<template>
  <div class="wrap">
    <div class="title">注意事项</div>
    <div class="def" style="margin-top:10px">挂售的售价是实时更新的，一旦点击确定，就有可能被买走，请慎重定价。</div>
    <div class="def" style="margin-top:20px">挂售的商品有两种被购买的方式。</div>
    <div class="def" style="margin-top:20px">1、当你的定价高于求购价时，游戏将在集市售卖，等待其他玩家购买时，则挂售成功。</div>
    <div class="def" style="margin-top:5px">2、当你的定价低于当前最高求购价，游戏将自动被当前定价范围内，最高的求购价的玩家自动购买。</div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  components: {},
})
export default class AttentionItem extends Vue {}
</script>

<style lang="scss" scoped>
.wrap {
  height: 160px;
  background: white;
  padding: 16px;
  .title {
    color: black;
    font-weight: bold;
    font-size: 13px;
  }
  .def {
    color: #969696;
    font-size: 12px;
  }
}
</style>
