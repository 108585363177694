import { render, staticRenderFns } from "./BottomLayout.vue?vue&type=template&id=f4dd920c&scoped=true&"
import script from "./BottomLayout.vue?vue&type=script&lang=js&"
export * from "./BottomLayout.vue?vue&type=script&lang=js&"
import style0 from "./BottomLayout.vue?vue&type=style&index=0&id=f4dd920c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f4dd920c",
  null
  
)

export default component.exports