





















import { Component, Vue, Prop } from "vue-property-decorator";
import { List } from "vant";
@Component({
  components: {
    List,
  },
})
export default class CurrentInfo extends Vue {
  list = [];
  loading = false;
  finished = false;
  name = "CurrentInfo";
  @Prop() playerList: any;
  created() {
  }
}
