<template>
  <div class="bgwrap">
    <div class="content">
      <div class="flex-column">
        <div style="font-weight: 800; text-align: center">确认价格</div>
        <div style="font-size: 14px; margin-top: 15px">
          <span>确定以</span>
          <span style="color: red; font-weight:800;margin-left: 5px; margin-right: 5px"
            >¥{{ price }}</span
          >
          <span>的售价，挂售补给？</span>
        </div>
        <div style="font-size: 14px; margin-top: 15px">
          请您注意：
        </div>
        <div style="font-size: 14px; margin-top: 15px">
          1. 补给售出后将获得等值蒸汽币，且卡券无法撤回。
        </div>
        <div style="font-size: 14px">
          2. 蒸汽币与人民币价值1:1，可用于{{this.globalState.packageName|appName}}消费，<span style="color:red;font-weight:900">无法提现</span>。
        </div>
      </div>
      <div class="bottom">
        <div class="think" @click="think">再想想</div>
        <div class="sure" @click="confirm">确认</div>
      </div>
    </div>
  </div>
</template>

<script>
import GlobalStateStore from "@/store/modules/global-state.store";
import { getModule } from "vuex-module-decorators";
export default {
  data() {
    return {};
  },
  props: {
    price: {
      type: [Number, String],
      default: 0,
    },
  },
  created(){
    this.globalState = getModule(GlobalStateStore);
  },
  methods: {
    confirm() {
      this.$emit("confirm");
      document.documentElement.style.overflowY = "scroll";
    },
    think() {
      this.$emit("think");
      document.documentElement.style.overflowY = "scroll";
    },
    
  },
  filters:{
    appName(value){
      if(value == 'com.vgn.gamepower'){
        return '游戏动力商城'
      }
      return '超级蒸汽商城'
    }
  }
};
</script>
<style lang="scss" scoped>
.bgwrap {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background: rgba($color: #000000, $alpha: 0.8);
  width: 100%;
  height: 100%;
  z-index: 9999;

  .content {
    background: white;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 320px;
    border-radius: 30px;
    .flex-column {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      padding: 30px 30px 20px 30px;
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 25px;
      padding-bottom: 10px;
      .think {
        background: white;
        border: 1px solid #dee0e2;
        color: black;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 131px;
        height: 38px;
        border-radius: 30px;
      }
      .sure {
        width: 131px;
        height: 38px;
        background: black;
        color: white;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 30px;
      }
    }
  }
}
</style>
