<template>
  <div class="bottom-flex">
    <template>
      <div class="hanggray" v-if="!price">
        确认价格
      </div>
      <div class="hang" v-else @click="ok"> 确认价格</div>
    </template>
    <WrapBg v-if="WrapState" @confirm="confirm" @think="think" :price="price" />
  </div>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";
import WrapBg from "@/views/hang-pay/components/Pop.vue";
import WechatSubscribe from "@/components/WechatSubscribe.vue";
import bazaarApi from "@/api/bazaar.api";
import InteractionLib from "@/utils/interaction.lib";
import { Toast } from "vant"
@Component({
  components: { WechatSubscribe,WrapBg },
})
export default class BottomLayout extends Vue {
  @Prop() detail_id;
  @Prop() price;
  WrapState = false
  created() {}
  
   async confirm() {
      this.show = false;

      await bazaarApi
        .toSellCard(this.detail_id, this.price)
        .then(() => {
          InteractionLib.updateGoldBlindBoxStatus()
          this.$router.push({
            name: "sell",
            params: {
              active: 1,
            },
          });
        })
        .catch((err) => Toast.fail({ message: err.message }));
   }
   think() {
      this.WrapState = false;
    }
    ok() {
      if(this.price == 0){
        Toast.fail('挂售价格不可为0')
        return
      }
      this.WrapState = true;
      if (this.WrapState) {
        document.documentElement.style.overflowY = "hidden";
      } else {
        document.documentElement.style.overflowY = "scroll";
      }
    }
}
</script>

<style lang="scss" scoped>
.bottom-flex {
  z-index: 100;
  background: white;
  .hang {
    width: 90%;
    margin: 0 auto;
    height: 40px;
    background: #000;
    border-radius: 19px;
    display: flex;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    color: white;
  }
   .hanggray {
    width: 90%;
    margin: 0 auto;
    height: 40px;
    background: #000000;
    opacity: 0.3;
    border-radius: 19px;
    display: flex;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    color: white;
  }
}
</style>
